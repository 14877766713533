<template>
  <v-app class="residential-agreement-parent">
    <v-container v-if="isVerified" class="bg-white h-100 py-0">
      <v-row>
        <v-col v-if="isRejected" md="12" class="px-10 py-0">
          <div class="agreement-approved agreement-rejected my-20">
            <div class="card">
              <div
                style="
                  border-radius: 200px;
                  height: 200px;
                  width: 200px;
                  background: #f8faf5;
                  margin: 0 auto;
                "
              >
                <i class="checkmark">&#x2715;</i>
              </div>
              <h1>Success</h1>
              <p>
                We received your rejection against agreement #
                {{ agreement_detail.barcode }}<br />
                we'll be in touch shortly!<br />
                Thank You!
              </p>
            </div>
          </div>
        </v-col>
        <v-col v-else-if="isSubmitted" md="12" class="px-10 py-0">
          <div class="agreement-approved my-20">
            <div class="card">
              <div
                style="
                  border-radius: 200px;
                  height: 200px;
                  width: 200px;
                  background: #f8faf5;
                  margin: 0 auto;
                "
              >
                <i class="checkmark">✓</i>
              </div>
              <h1>Success</h1>
              <p>
                We received your approval against agreement #
                {{ agreement_detail.barcode }}<br />
                we'll be in touch shortly!<br />
                Thank You!
              </p>
            </div>
          </div>
        </v-col>
        <v-col v-else md="12" class="px-10 py-0">
          <v-layout class="mx-15 my-5">
            <v-flex md4 class="my-auto">
              <div class="brand-logo">
                <img :src="siteLogo()" alt="Logo" />
              </div>
            </v-flex>
            <v-flex
              md4
              class="my-auto text-center font-size-24 font-weight-700"
              >{{ agreement_detail.page_title }}</v-flex
            >
            <v-flex md4 class="my-auto text-right">
              <v-btn
                class="custom-bold-button white--text"
                color="cyan"
                v-on:click="signaturePopup('owner', true)"
                >Accept</v-btn
              >
              <v-btn
                class="custom-bold-button white--text mx-4"
                color="red lighten-1"
                v-on:click="rejectDialog = true"
                >Reject</v-btn
              >
              <v-btn class="custom-bold-button" v-on:click="closeWindow()"
                >Close</v-btn
              >
            </v-flex>
          </v-layout>
          <v-container fluid class="resident-agreement">
            <v-form
              ref="agreementForm"
              v-model.trim="formValid"
              lazy-validation
              v-on:submit.stop.prevent="consentAgreement()"
            >
              <v-container fluid class="white-background agreement">
                <v-row>
                  <v-col offset-md="1" md="10">
                    <p>
                      <span
                        class="font-weight-700 custom-border-bottom-black"
                        >{{ agreementTitle }}</span
                      >
                    </p>
                    <div class="d-flex">
                      本人 I
                      <v-text-field
                        dense
                        filled
                        solo
                        flat
                        :rules="[
                          validateRules.required(attributes.name, 'Name'),
                          validateRules.minLength(attributes.name, 'Name', 1),
                          validateRules.maxLength(attributes.name, 'Name', 100),
                        ]"
                        placeholder="Enter your Full Name"
                        v-model="attributes.name"
                        class="border-bottom"
                        hide-details
                        color="cyan"
                      >
                      </v-text-field>
                      , 护照号码 Passport /FIN Number :
                      <v-text-field
                        dense
                        filled
                        solo
                        flat
                        placeholder="Enter your Passport /FIN Number"
                        :rules="[
                          validateRules.required(
                            attributes.passport,
                            'Passport /FIN Number'
                          ),
                          validateRules.minLength(
                            attributes.passport,
                            'Passport /FIN Number',
                            1
                          ),
                          validateRules.maxLength(
                            attributes.passport,
                            'Passport /FIN Number',
                            200
                          ),
                        ]"
                        v-model="attributes.passport"
                        class="border-bottom"
                        hide-details
                        color="cyan"
                      >
                      </v-text-field
                      >,
                    </div>
                    <div class="d-flex">
                      公司名字 Company Name :
                      <v-text-field
                        dense
                        filled
                        solo
                        flat
                        placeholder="Enter your Company Name"
                        :rules="[
                          validateRules.required(
                            attributes.company,
                            'Company Name'
                          ),
                          validateRules.minLength(
                            attributes.company,
                            'Company Name',
                            1
                          ),
                          validateRules.maxLength(
                            attributes.company,
                            'Company Name',
                            200
                          ),
                        ]"
                        v-model="attributes.company"
                        class="border-bottom"
                        hide-details
                        color="cyan"
                      >
                      </v-text-field>
                    </div>
                    <div class="d-flex">
                      住宿地址 Address :
                      <v-text-field
                        dense
                        filled
                        solo
                        flat
                        placeholder="Enter your Full Address"
                        :rules="[
                          validateRules.required(attributes.address, 'Address'),
                          validateRules.minLength(
                            attributes.address,
                            'Address',
                            1
                          ),
                          validateRules.maxLength(
                            attributes.address,
                            'Address',
                            200
                          ),
                        ]"
                        v-model="attributes.address"
                        class="border-bottom"
                        hide-details
                        color="cyan"
                      >
                      </v-text-field>
                    </div>
                    <p class="pt-4 m-0">
                      清楚了解详细资讯如下 hereby acknowledge and understand
                      that:
                    </p>
                    <p class="pt-2 m-0" :class="{ 'mb-4': !readmore }">
                      1.
                      上班地点与住宿的距离和时间，不得以此为由，出现迟到的问题。<br />The
                      travelling distance and time required from Site Address to
                      Working Place.<span v-if="!readmore">.....</span>
                    </p>
                    <template v-if="readmore">
                      <p class="my-4">
                        2.
                        住宿地址为合法并且可以登记在新加坡房屋局（HDB）以及人力部，如果因为无法登记地址或所提
                        供的地址不属实而超过 14
                        天有效期（自入境当天开始），本人将自行承担因无法登记地址而造成在新
                        加坡不能正常办理准证卡以及新加坡政府部门给予的一切处罚。<br />
                        The renovation address provided is legal and able to
                        register in Singapore HDB &amp; MOM. I understand that
                        the address must be registered in MOM within 14 days
                        (start from the arrival date) else I will bear all
                        consequences from relevant authority.
                      </p>
                      <p class="my-4">
                        3. 所居住房屋必须符合新加坡房屋局（HDB）的要求:
                        任何规格的政府租屋，公寓或任何私人住宅，房
                        东与租客人员总数不得超过 6
                        人。入境新加坡当天，新加坡中介 Inter Island Manpower Pte
                        Ltd 将会去
                        到住宿检查及拍照，如果经过新加坡中介检查到房屋有任何不合法的地方，当天必须更换到新加坡中
                        介 Inter Island Manpower Pte Ltd
                        所找的房子，费用要全数缴纳。<br />
                        The renovation address must meet the requirement of HDB:
                        The maximum occupants in HDB, Condo or any private
                        property must not exceed 6 pax. Inter Island Manpower
                        Pte Ltd will conduct inspection and photo taking on the
                        arrival date, if the renovation address does not meet
                        the Singapore Government regulations, I shall appointed
                        Inter Island Manpower Pte Ltd to source for legal
                        accommodation and bear all the costs.
                      </p>
                      <p class="my-4">
                        本人在此声明以上的资料全部属实并在此授权新加坡中介公司
                        Inter Island Manpower Pte Ltd 登记地址
                        如有虚假或不合法，新加坡中介公司 Inter Island Manpower
                        Pte Ltd 将会如实呈报给雇主公司，本人将
                        接受人力部和雇主公司给予的任何处分，新加坡中介公司 Inter
                        Island Manpower Pte Ltd 以及雇主公司
                        将不承担任何责任。<br />
                        I hereby acknowledged that the information provided
                        stated above are true and legit and authorized Inter
                        Island Manpower Pte Ltd to update address on behalf.
                        Inter Island Manpower Pte Ltd will report to employing
                        company and Ministry of Manpower if any false
                        information provided, I will bear all consequences from
                        relevant authority, Inter Island Manpower Pte Ltd and
                        employing company would not be held responsible for any
                        matters arising from my accommodation issues.
                      </p>
                    </template>
                    <v-btn
                      color="cyan"
                      class="white--text"
                      small
                      v-on:click="readmore = !readmore"
                    >
                      <template v-if="readmore">Read Less...</template>
                      <template v-else>Read More...</template>
                    </v-btn>
                    <p
                      class="mt-6 link d-flex"
                      :class="{ 'mb-20': !signature.owner }"
                      v-on:click="signaturePopup('owner', false)"
                    >
                      Signature/ Date 签名/日期:
                      <v-img
                        v-if="signature.owner"
                        contain
                        max-height="100"
                        max-width="200"
                        height="100"
                        class="ml-4"
                        :src="signature.owner"
                      ></v-img>
                      <em v-else class="signature">click here to sign</em>
                    </p>
                    <p class="my-4 d-flex width-50">
                      Contact No 电话号码:
                      <v-text-field
                        dense
                        filled
                        solo
                        flat
                        placeholder="Enter your Contact No"
                        :rules="[
                          validateRules.required(
                            attributes.contact,
                            'Contact No'
                          ),
                        ]"
                        v-mask="'############'"
                        v-model="attributes.contact"
                        class="border-bottom"
                        hide-details
                        color="cyan"
                      >
                      </v-text-field>
                    </p>
                  </v-col>
                </v-row>
                <Dialog :commonDialog.sync="signatureDialog">
                  <template v-slot:title> Signature </template>
                  <template v-slot:body>
                    <v-row>
                      <v-col md="10" class="py-0" offset-md="1">
                        <div
                          id="signature-pad"
                          ref="signature_div"
                          class="custom-border-grey-dashed"
                        >
                          <canvas ref="signature"></canvas>
                        </div>
                      </v-col>
                      <v-col md="10" class="py-0" offset-md="1">
                        <p class="float-right m-0">
                          <v-btn
                            content="Click here to clear signature"
                            v-tippy="{ arrow: true, placement: 'top' }"
                            icon
                            small
                            class="float-right"
                            v-on:click="signatureRef.clear()"
                          >
                            <v-icon>mdi-close-circle-outline</v-icon>
                          </v-btn>
                        </p>
                      </v-col>
                    </v-row>
                  </template>
                  <template v-slot:action>
                    <v-btn
                      class="mx-2 custom-grey-border custom-bold-button white--text"
                      color="cyan"
                      v-on:click="signatureSave()"
                    >
                      Submit
                    </v-btn>
                    <v-btn
                      class="mx-2 custom-grey-border custom-bold-button"
                      v-on:click="signatureClose()"
                    >
                      Cancel
                    </v-btn>
                  </template>
                </Dialog>
              </v-container>
            </v-form>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
    <Dialog :commonDialog.sync="dialog">
      <template v-slot:title> Verification </template>
      <template v-slot:body>
        <v-form
          ref="verificationForm"
          v-model.trim="formValid"
          lazy-validation
          v-on:submit.stop.prevent="verifyCustomer"
        >
          <v-row>
            <v-col md="12">
              <v-text-field
                v-model.trim="verificationCode"
                dense
                filled
                label="Security Code"
                solo
                flat
                :disabled="formLoading || pageLoading"
                :loading="formLoading"
                color="cyan"
                :rules="[
                  validateRules.required(verificationCode, 'Security Code'),
                  validateRules.minLength(verificationCode, 'Security Code', 4),
                  validateRules.maxLength(
                    verificationCode,
                    'Security Code',
                    10
                  ),
                ]"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="!formValid || formLoading || pageLoading"
          :loading="formLoading"
          class="mx-2 custom-grey-border custom-bold-button white--text"
          color="cyan"
          v-on:click="verifyCustomer"
        >
          Submit
        </v-btn>
      </template>
    </Dialog>
    <Dialog :commonDialog.sync="rejectDialog">
      <template v-slot:title> Confirmation </template>
      <template v-slot:body>
        <h4>Are you sure ? You want to reject this agreement ?</h4>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="formLoading || pageLoading"
          :loading="formLoading"
          class="mx-2 custom-grey-border custom-bold-button white--text"
          color="red lighten-1"
          v-on:click="rejectAgreement()"
        >
          Yes ! Reject
        </v-btn>
        <v-btn
          :disabled="formLoading || pageLoading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="rejectDialog = false"
        >
          No ! Cancel
        </v-btn>
      </template>
    </Dialog>
    <template v-if="validationErrorBag.length">
      <div class="error-bag">
        <v-alert
          dense
          top
          v-for="(error, index) in validationErrorBag"
          :key="index"
          type="error"
        >
          {{ error }}
        </v-alert>
      </div>
    </template>
  </v-app>
</template>
<style type="text/scss" scoped>
.residential-agreement-parent .layout.mx-15 {
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  position: sticky;
  top: 0;
  z-index: 99;
  background-color: #fff;
}

.residential-agreement-parent .layout .font-size-24.text-center {
  text-align: left !important;
  font-size: 18px !important;
  line-height: 1.3;
  padding-left: 10px;
}

.residential-agreement-parent .container .row > .px-10 {
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.residential-agreement-parent
  .layout
  .v-btn:not(.v-btn--round).v-size--default {
  height: 30px;
  min-width: 65px;
  padding: 0 16px;
  margin: 2px 0 !important;
  width: 90%;
  font-size: 0.375rem;
}

.residential-agreement-parent
  .layout
  .v-btn:not(.v-btn--round).v-size--default
  span.v-btn__content {
  font-size: 12px;
}
.residential-agreement-parent
  .v-text-field.v-text-field--solo.v-input--dense
  > .v-input__control {
  margin-left: 6px;
}

.signature {
  font-size: 24px;
  color: #777;
  font-weight: 600;
}
.page-paper {
  min-height: 842px;
}

.error-bag {
  position: fixed;
  right: 25px;
  top: 25px;
  z-index: 9999 !important;
}

.agreement-approved {
  text-align: center;
  padding: 40px 0;
  background: #ebf0f5;
}

.agreement-rejected {
  text-align: center;
  padding: 40px 0;
  background: #ebf0f5;
}

.agreement-approved h1 {
  color: #88b04b;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 10px;
}
.agreement-rejected h1 {
  color: #ed3e46;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 10px;
}
.agreement-approved p {
  color: #404f5e;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  margin: 0;
}
.agreement-rejected p {
  color: #404f5e;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  margin: 0;
}
.agreement-approved i {
  color: #9abc66;
  font-size: 100px;
  line-height: 200px;
  margin-left: -15px;
}
.agreement-rejected i {
  color: #ed3e46;
  font-size: 100px;
  line-height: 200px;
  margin-left: -15px;
}
.agreement-approved .card {
  background: white;
  padding: 60px;
  border-radius: 4px;
  box-shadow: 0 2px 3px #c8d0d8;
  display: inline-block;
  margin: 0 auto;
}
</style>
<script>
import { mapGetters } from "vuex";
import objectPath from "object-path";
import Dialog from "@/view/pages/partials/Dialog.vue";
import ValidationMixin from "@/core/plugins/validation-mixin";
import { VERIFY_AGREEMENT_CUSTOMER } from "@/core/services/store/request.module";
import CommonMixin from "@/core/plugins/common-mixin.js";
import SignaturePad from "signature_pad";

export default {
  name: "agreement-approve",
  mixins: [ValidationMixin, CommonMixin],
  data: () => {
    return {
      dialog: true,
      readmore: false,
      formValid: true,
      formLoading: false,
      isRejected: false,
      isVerified: false,
      isSubmitted: false,
      pageLoading: false,
      rejectDialog: false,
      verificationCode: null,
      securityToken: null,
      today_date: null,
      validationErrorBag: new Array(),
      agreement_detail: new Object(),
      company_detail: null,
      sales_signature: null,
      agreementTitle: "住宿地址声明书 Site Address Acknowledgement",
      companyName: "Genic Solution Pte Ltd.",
      companyAddress: "8 Burn Road, #07-16 Trivex, Singapore 369977",
      attributes: {
        name: null,
        passport: null,
        company: null,
        address: null,
        contact: null,
      },
      signature: {
        owner: null,
      },
      signatureRef: null,
      signatureType: null,
      signatureDialog: false,
    };
  },
  components: {
    Dialog,
  },
  methods: {
    rejectAgreement() {
      const _this = this;
      _this.formLoading = true;
      _this.$store
        .dispatch(VERIFY_AGREEMENT_CUSTOMER, {
          url: "agreements/customer/reject",
          data: { code: _this.verificationCode },
        })
        .then(() => {
          _this.isRejected = true;
          _this.rejectDialog = false;
        })
        .catch((data) => {
          if (data && data.data && data.data.message) {
            _this.validationErrorBag = data.data.message.split("<br>");
            setTimeout(function () {
              _this.validationErrorBag = [];
            }, 5000);
          } else {
            _this.validationErrorBag.push(
              "Something went wrong, Please try again later."
            );
            setTimeout(function () {
              _this.validationErrorBag = [];
            }, 5000);
          }
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    updateOrCreate(param) {
      const _this = this;
      _this.formLoading = true;
      _this.$store
        .dispatch(VERIFY_AGREEMENT_CUSTOMER, {
          url: "agreements/customer/submit",
          data: { code: _this.verificationCode, signature: param },
        })
        .then(() => {
          _this.isSubmitted = true;
        })
        .catch((data) => {
          if (data && data.data && data.data.message) {
            _this.validationErrorBag = data.data.message.split("<br>");
            setTimeout(function () {
              _this.validationErrorBag = [];
            }, 5000);
          } else {
            _this.validationErrorBag.push(
              "Something went wrong, Please try again later."
            );
            setTimeout(function () {
              _this.validationErrorBag = [];
            }, 5000);
          }
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    verifyCustomer() {
      const _this = this;

      if (!_this.$refs.verificationForm.validate()) {
        return false;
      }

      _this.formLoading = true;
      _this.isVerified = false;

      _this.validationErrorBag = new Array();

      _this.$store
        .dispatch(VERIFY_AGREEMENT_CUSTOMER, {
          url: "agreements/verify",
          data: { code: _this.verificationCode },
          token: _this.securityToken,
        })
        .then(({ data }) => {
          _this.agreement_detail = data.agreement_detail;
          _this.company_detail = data.company_detail;
          _this.companyName = data.company_detail.company_name;
          _this.companyAddress = data.company_detail.address;
          _this.systemName = data.company_detail.company_name;
          _this.systemNRIC = data.company_detail.company_nric;
          _this.sales_signature = data.sales_signature;
          _this.today_date = data.today_date;
          _this.dialog = false;
          _this.isVerified = true;
        })
        .catch(({ response }) => {
          if (response && response.data && response.data.message) {
            _this.validationErrorBag = response.data.message.split("<br>");
            setTimeout(function () {
              _this.validationErrorBag = [];
            }, 5000);
          } else {
            _this.validationErrorBag.push(
              "Something went wrong, Please try again later."
            );
            setTimeout(function () {
              _this.validationErrorBag = [];
            }, 5000);
          }
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    siteLogo() {
      const menuAsideLeftSkin = this.layoutConfig("brand.self.theme");
      // set brand logo
      const logoObject = this.layoutConfig("self.logo");

      let logo;
      if (typeof logoObject === "string") {
        logo = logoObject;
      }
      if (typeof logoObject === "object") {
        logo = objectPath.get(logoObject, menuAsideLeftSkin + "");
      }
      if (typeof logo === "undefined") {
        const logos = this.layoutConfig("self.logo");
        logo = logos[Object.keys(logos)[0]];
      }
      return process.env.VUE_APP_BASE_URL + logo;
    },
    closeWindow() {
      window.close();
    },
    signaturePopup(type, validate) {
      const _this = this;

      const validateStatus = _this.$refs.agreementForm.validate();
      _this.validationErrorBag = [];
      const formErrors = _this.validateForm(_this.$refs.agreementForm);
      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          _this.validationErrorBag.push(formErrors[i]);
        }
        setTimeout(function () {
          _this.validationErrorBag = [];
        }, 5000);
        return false;
      }

      if (!validateStatus) {
        return false;
      }

      if (validate) {
        if (_this.signature.owner) {
          _this.submitApproval();
          return false;
        }
      }

      _this.signatureDialog = true;
      _this.signatureType = type;
      _this.initSign();
    },
    initSign() {
      const _this = this;
      setTimeout(function () {
        _this.$nextTick(() => {
          let ccanvas = _this.$refs["signature"];
          let cparentDiv = document.getElementById("signature-pad");
          let cparentWidth = cparentDiv.offsetWidth;
          ccanvas.setAttribute("width", cparentWidth);
          _this.signatureRef = new SignaturePad(ccanvas);
        });
      }, 100);
    },
    signatureSave() {
      const _this = this;

      if (_this.signatureRef.isEmpty()) {
        return false;
      }

      _this.signature[_this.signatureType] = _this.signatureRef.toDataURL();
      _this.signatureClose();
    },
    submitApproval() {
      const _this = this;

      const validateStatus = _this.$refs.agreementForm.validate();
      _this.validationErrorBag = [];
      const formErrors = _this.validateForm(_this.$refs.agreementForm);
      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          _this.validationErrorBag.push(formErrors[i]);
        }
        setTimeout(function () {
          _this.validationErrorBag = [];
        }, 5000);
        return false;
      }

      if (!validateStatus) {
        return false;
      }

      const formData = new Object({
        attributes: _this.attributes,
        owner_url: _this.signature.owner,
        code: _this.verificationCode,
      });

      _this.formLoading = true;

      _this.$store
        .dispatch(VERIFY_AGREEMENT_CUSTOMER, {
          url: "agreements/residential-agreement/accept",
          data: formData,
          token: _this.securityToken,
        })
        .then(() => {
          _this.isSubmitted = true;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    signatureClose() {
      this.signatureDialog = false;
      this.signatureRef.clear();
      this.signatureRef = null;
      this.signatureType = null;
    },
  },
  created() {
    const _this = this;

    if (_this.$route.query && _this.$route.query.token) {
      _this.securityToken = _this.$route.query.token;
    } else {
      _this.dialog = false;
      _this.closeWindow();
    }
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
};
</script>
